import { Atom } from ":mods";


export function createEducationInfoInputs(){
  const Qualification = Atom.Form.createInput("", {
    required: true,
  });
  const StudyField = Atom.Form.createSelect("", {
    required: false,
  });


  const Actions = Atom.Form.createFormActions({
    Qualification,
    StudyField,
   
  });
  return {
    Actions,
    Qualification,
    StudyField,
  }
}